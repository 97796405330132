import React, { useState, useEffect } from 'react';
import { BasePage } from "./BasePage.js"
import { makeStyles } from '@material-ui/core/styles';
import { Link, Table, TableRow, TableCell, TableContainer, TableBody, Container, Box, Typography } from '@material-ui/core';
import { getAllocationsForBenefit, downloadEmployeeSummary } from '../api/benefit-api.js';
import { getDayCareBenefitId } from '../util/helper.js';

const useStyles = makeStyles({
    table: {
        width: "700px",
        '& tr:first-child': {
            '& td': {
                borderTop: "1px blue solid"
            }
        }
    }
})

function AdminPage(props) {
    const classes = useStyles()
    const { userId } = props.match.params
    const [hasDayCareAllocations, setHasDayCareAllocations] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllocationsForBenefit(userId, getDayCareBenefitId())
            if (result && result.currentBenefitAllocationsMonthlyNet.reduce((acc, cur) => { return acc = acc + cur }, 0)) {
                setHasDayCareAllocations(true)
            }
        }
        fetchData()
    }, [userId])

    return (
        <BasePage>
            <Typography variant="h4">
                Kitöltendő dokumentumok
            </Typography>
            <br />
            <Container>
                <Box align="left">
                    Ezek a dokumentumok szükségesek az igénylésedhez. Kérlek nyomtasd ki a megadott példányszámban, és töltsd ki őket.
                </Box>
                <br />
                <TableContainer component="div">
                    <Table stickyHeader className={classes.table} aria-label="Documents table">
                        <TableBody>
                            <TableRow hover>
                                <TableCell> <Link href="" onClick={(e) => {
                                    e.preventDefault()
                                    downloadEmployeeSummary(userId)
                                }}> Igénylés nyilatkozat </Link> </TableCell>
                                <TableCell align="right">2 példányban</TableCell>
                            </TableRow>
                            {hasDayCareAllocations &&
                                <TableRow hover>
                                    <TableCell> <Link href="/nyilatkozat_bolcsodei_szolgaltatas.doc"> Nyilatkozat bölcsődei/óvodai szolgáltatás költségének térítéséhez </Link> </TableCell>
                                    <TableCell align="right">2 példányban</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

        </BasePage >
    )

}

export default AdminPage